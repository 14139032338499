<template>
  <el-dialog
    title="修改任务奖励"
    :visible.sync="$_visible"
    width="500px">
    <el-form
      label-position="left"
      label-width="110px">
      <el-form-item label="完成程度">
        <el-select
          size="small"
          style="width: 320px;"
          v-model="formInfo.completeness"
          placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="理由说明">
        <el-input
        type="textarea"
        style="width: 320px;"
        v-model="formInfo.completionReason"
        :autosize="{minRows: 4}"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleUpdateAwards">
        确认修改
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  data () {
    return {
      options: [{
        value: 100,
        label: '100%（全额发放奖励）'
      }, {
        value: 80,
        label: '80%（发放80%奖励）'
      }, {
        value: 50,
        label: '50%（发放50%奖励）'
      }, {
        value: 20,
        label: '20%（全额发放奖励）'
      }, {
        value: 0,
        label: '0（不发放奖励）'
      }],
      formInfo: {
        completeness: '',
        completionReason: ''
      }
    }
  },
  methods: {
    // 修改任务奖励
    handleUpdateAwards () {
      api.adminUpdateReward(this.taskId, { ...this.formInfo }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.closeDialog()
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
